<template>
  <div class="navigation">
    <div class="logo">
      <a href="/">
        <span>
          <img src="/favicon.ico" alt="">
        </span>
        <span>Assistant Bot</span>
      </a>
    </div>
    <ul class="menu">
      <li class="home" :class="{active : uri === '/'}">
        <a href="/">{{ t('components.homeNav.homepage') }}</a>
      </li>
      <li class="flow-chart" :class="{active : uri === '/flow_chart'}">
        <a href="/flow_chart">{{ t('components.homeNav.flowChart') }}</a>
      </li>
      <li class="docs" :class="{active : uri === '/docs'}">
        <a href="/docs">{{ t('components.homeNav.docs') }}</a>
      </li>
      <li class="donate" :class="{active : uri === '/donate'}">
        <a href="/donate">{{ t('components.homeNav.donate') }}</a>
      </li>
      <li class="link">
        <a :href="`https://t.me/${username}`" target="_blank">LINK BOT</a>
      </li>
      <li class="login">
        <a href="/login">{{ t('components.homeNav.login') }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { computed } from '@vue/runtime-core'
import { reactive, toRefs } from '@vue/reactivity'

export default {
  name: 'HomeNav',
  setup() {
    const { t } = useI18n()
    const Store = useStore()
    const Route = useRoute()
    const data = reactive({
      uri: Route.path,
      username: computed(() => Store.getters.get('username'))
    })

    return { ...toRefs(data), t }
  }
}
</script>

<style lang="scss" scoped>
.navigation {
  width: 100%;
  min-width: 1100px;
  height: 90px;
  line-height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 30px;
  background: #f7f7f7;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  .logo {
    float: left;
    a {
      border: none;
      display: table;
      span {
        display: table-cell;
        vertical-align: middle;
        font-size: 22px;
        font-weight: bold;
        img {
          width: auto;
          height: 50px;
          margin-right: 15px;
        }
      }
    }
  }
  .menu {
    float: right;
    height: 100%;
    li {
      float: left;
      font-size: 18px;
      position: relative;
      a {
        display: block;
        width: 100%;
        height: 100%;
        border: none;
        padding: 0 30px;
      }
    }
    li:hover {
      background: rgba(0, 0, 0, 0.05);
    }
    li:hover::before {
      content: "";
      width: 100%;
      height: 4px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #60b7ff;
    }
    .active {
      background: rgba(0, 0, 0, 0.05);
    }
    .active::before {
      content: "";
      width: 100%;
      height: 4px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #60b7ff;
    }
  }
}
</style>
