<template>
  <div class="app flow-chart">
    <Navigation/>
    <div class="home-content flow-chart-content">
      <div class="flow-chart-content-in">
        <img src="../../assets/img/flow-chart.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '../../components/HomeNav.vue'

export default {
  name: 'FlowChart',
  components: {
    Navigation
  }
}
</script>
